import axios from "axios";

export default {
    validateLicense(licenseKey, emailId, deviceId, deviceModel) {
        let url = process.env.VUE_APP_BASE_URL + "validateLicenseWithEmailnKey";

        return axios.get(
            url +
            "?licenseKey=" +
            licenseKey +
            "&emailId=" +
            emailId +
            "&deviceId=" +
            deviceId +
            "&deviceModel=" +
            deviceModel
        );
    },

    getLicenseDetails(licenseKey, emailId) {
        let url = process.env.VUE_APP_BASE_URL + "getLicenceDetailsByEmailnKey";

        return axios.get(url + "?licenseKey=" + licenseKey + "&emailId=" + emailId);
    },
    async getFulcrumLicense(licenseKey, emailId, deviceId, deviceModel) {
        let token = null;
        try {
            const tokenobject = await this.getToken();

            token = tokenobject.data;


            const config = {
                headers: { Authorization: "Bearer " + token }
            };


            let url = process.env.VUE_APP_BASE_URL + "createFulcrumLicenseKey";
            const result = await axios.get(url +
                "?licenseKey=" +
                licenseKey +
                "&emailId=" +
                emailId +
                "&deviceId=" +
                deviceId +
                "&deviceModel=" +
                deviceModel, config);
            return result;
        }

        catch (error) {
            console.log(error);
        }

    },
    async getToken() {
        let url = process.env.VUE_APP_BASE_URL + "createFulcrumToken";
        if (url == null) throw "Cannot get URL";

        let client_id = process.env.VUE_APP_AZUREAUTH_CLIENTID;
        let client_secret = process.env.VUE_APP_AZUREAUTH_CLIENTSECRET;
       
        let tokenResult = await axios.get(url +
            "?clientId=" +
            client_id +
            "&clientSecret=" +
            client_secret);

        return tokenResult;
    }, getTokenDirect() {
        // debugger;

        let tokenUrl = process.env.VUE_APP_AZUREAUTH_URL;
        let client_id = process.env.VUE_APP_AZUREAUTH_CLIENTID;
        let client_secret = process.env.VUE_APP_AZUREAUTH_CLIENTSECRET;

        //const keyValues = [
        //    //new KeyValuePair<string, string>("tenant_id", TenantId),
        //    { key: "client_id", value: client_id },
        //    { key: "client_secret", value: client_secret },
        //    { key: "scope", value: "api://" + client_id + "/.default" },
        //    { key: "grant_type", value: "client_credentials" }
        //];
        const postValues = {
            client_id: client_id,
            client_secret: client_secret,
            scope: "api://" + client_id + "/.default",
            grant_type: "client_credentials"
    };
        
        axios.post(tokenUrl, postValues).then((response) => {
            let content = response.Content.ReadAsString();
            let Token = JSON.parse(content);
            return Token.AccessToken;
        }).catch(
(error) => {
                console.log(error);
            }
            
        );
        return null;
    },
  addDeviceMatrix(payload) {
    let url = process.env.VUE_APP_BASE_URL + "AddDeviceMatrix";

    return axios.post(url, payload);
  },
};
